<!-- eslint-disable max-len -->
<template>
	<v-card flat elevation="0" class="min-height-screen h-full pb-5">
		<v-toolbar flat class="border-b-2 mb-2 border--secondary px-0 py-3 !h-85px">
			<v-toolbar-title><strong>Shopping Cart</strong></v-toolbar-title>
		</v-toolbar>

		<div class="px-4" style="background-color: inherit">
			<transition>
				<div
					v-if="!hasCartProducts && !isLoading"
					class="d-flex align-center justify-center w-full h-full py-16"
				>
					<p class="font-weight-bold text-lg text-center">
						You don't have any products in the cart
					</p>
				</div>
				<div
					v-else-if="isLoading"
					class="d-flex align-center justify-center w-full h-full"
					style="height: 400px"
				>
					<loader />
				</div>
				<div v-else-if="hasCartProducts && !isLoading">
					<div class="mb-10">
						<v-card
							v-for="product in cartProducts"
							:key="product.id"
							flat
							elevation="0"
							class="py-3 px-3 border-2 border--secondary mt-4 rounded-small"
						>
							<div class="d-flex flex-no-wrap align-start">
								<div class="pa-2 border-2 border--secondary rounded-small mr-3">
									<v-avatar size="44" tile v-if="product.imageUrl">
										<v-img
											v-if="product.imageUrl.startsWith('http')"
											class="rounded"
											:lazy-src="product.imageUrl"
											:src="product.imageUrl"
										/>
										<v-img
											v-else
											class="rounded"
											:lazy-src="`${IMAGE_BASE_URL}/${product.imageUrl}`"
											:src="`${IMAGE_BASE_URL}/${product.imageUrl}`"
										/>
									</v-avatar>
									<v-avatar v-else size="44" tile>
										<v-img
											class="rounded"
											lazy-src="/placeholder-image.png"
											src="/placeholder-image.png"
										/>
									</v-avatar>
								</div>
								<div class="flex-grow-1">
									<strong class="text-sm mb-3">{{ product.name }}</strong>
									<div>
										<span class="primary--text font-weight-bold text-sm mb-2">
											{{
												product.dealPrice !== null && product.dealPrice > 0
													? currencyFormatter.format(product.dealPrice)
													: currencyFormatter.format(product.unitCost)
											}}
										</span>
										<span
											v-if="product.dealPrice !== null && product.dealPrice > 0"
											class="text-decoration-line-through text--secondary text-xs"
											>{{ currencyFormatter.format(product.unitCost) }}</span
										>
									</div>

									<div class="d-flex justify-space-between align-center flex-wrap">
										<div class="d-flex align-center my-2">
											<button
												class="product-btn product-btn-minus pa-1 d-flex align-center"
												:class="{ 'cursor-default': product.quantity <= 1 }"
												:disabled="product.quantity <= 1"
												@click="decreaseProductQuantity(product.id, product.quantity)"
											>
												<v-icon small>mdi-minus</v-icon>
											</button>
											<span class="mx-2 text-sm font-weight-bold">{{ product.quantity }}</span>

											<button
												class="product-btn product-btn-plus pa-1 d-flex align-center"
												@click="increaseProductQuantity(product.id)"
											>
												<v-icon small> mdi-plus </v-icon>
											</button>
										</div>

										<v-btn
											text
											color="secondary"
											class="px-2 font-weight-bold"
											@click="$store.commit('REMOVE_FROM_CART_PRODUCTS', product.id)"
											>Remove</v-btn
										>
									</div>
								</div>
							</div>
						</v-card>
					</div>
					<h4 class="text-h6 font-weight-bold mb-3">Bill Details</h4>

					<div>
						<div class="border-b-2 border--secondary pb-4">
							<div class="d-flex align-center justify-space-between">
								<span class="text--secondary">Item Total</span>
								<p class="mb-0 font-weight-bold">
									{{
										currencyFormatter.format(orderAmountData.totalOrderAmountExcludeDeliveryCharge)
									}}
								</p>
							</div>
							<div class="d-flex align-center justify-space-between mt-2">
								<span class="text--secondary">Delivery Fee</span>
								<p class="mb-0 font-weight-bold">
									+ {{ currencyFormatter.format(orderAmountData.deliveryCharge) }}
								</p>
							</div>
						</div>
						<div class="d-flex align-center justify-space-between mt-4 font-weight-bold">
							<p class="mb-0">Order Total</p>
							<p class="mb-0">{{ currencyFormatter.format(orderAmountData.totalOrderAmount) }}</p>
						</div>
					</div>

					<div class="mt-10 pb-16">
						<v-text-field
							outlined
							hide-details
							ref="voucherCodeRef"
							v-model.trim="voucherCode"
							class="rounded-small"
							@input="(val) => (this.voucherCode = val.toUpperCase())"
							placeholder="Voucher code"
						>
							<template #prepend-inner>
								<span class="pr-1 voucher-icon">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="currentColor"
									>
										<g clip-path="url(#clip0_92_1779)">
											<path
												d="M21.75 21.0001H2.25003C1.00931 21.0001 1.14441e-05 19.9908 1.14441e-05 18.75V15C1.14441e-05 14.5855 0.335451 14.25 0.750016 14.25C1.99073 14.25 3.00003 13.2407 3.00003 12C3.00003 10.7593 1.99073 9.74999 0.750016 9.74999C0.335451 9.75004 1.14441e-05 9.4146 1.14441e-05 9.00004V5.25001C1.14441e-05 4.0093 1.00931 3 2.25003 3H21.75C22.9908 3 24.0001 4.0093 24.0001 5.25001V9.00004C24.0001 9.4146 23.6646 9.75004 23.2501 9.75004C22.0093 9.75004 21 10.7593 21 12.0001C21 13.2408 22.0093 14.2501 23.2501 14.2501C23.6646 14.2501 24.0001 14.5855 24.0001 15.0001V18.7501C24 19.9908 22.9908 21.0001 21.75 21.0001ZM1.50002 15.6746V18.75C1.50002 19.1639 1.83622 19.5 2.25003 19.5H21.75C22.1639 19.5 22.5001 19.1639 22.5001 18.75V15.6746C20.7906 15.326 19.5 13.8113 19.5 12C19.5 10.1887 20.7905 8.67409 22.5001 8.32542V5.25001C22.5001 4.83621 22.1639 4.50001 21.75 4.50001H2.25003C1.83622 4.50001 1.50002 4.83621 1.50002 5.25001V8.32547C3.20948 8.67409 4.50004 10.1887 4.50004 12.0001C4.50004 13.8114 3.20948 15.326 1.50002 15.6746Z"
											/>
											<path
												d="M9.04834 17.7266L13.5467 5.73081L14.9512 6.25749L10.4528 18.2532L9.04834 17.7266Z"
											/>
											<path
												d="M8.45193 12.2525C7.21121 12.2525 6.20191 11.2432 6.20191 10.0025C6.20191 8.76174 7.21121 7.75244 8.45193 7.75244C9.69264 7.75244 10.7019 8.76174 10.7019 10.0025C10.7019 11.2432 9.69264 12.2525 8.45193 12.2525ZM8.45193 9.25245C8.03812 9.25245 7.70192 9.58865 7.70192 10.0025C7.70192 10.4163 8.03812 10.7525 8.45193 10.7525C8.86574 10.7525 9.20193 10.4163 9.20193 10.0025C9.20193 9.58865 8.86574 9.25245 8.45193 9.25245Z"
											/>
											<path
												d="M16.2044 16.9038C14.9637 16.9038 13.9544 15.8945 13.9544 14.6538C13.9544 13.4131 14.9637 12.4038 16.2044 12.4038C17.4451 12.4038 18.4544 13.4131 18.4544 14.6538C18.4544 15.8945 17.4451 16.9038 16.2044 16.9038ZM16.2044 13.9038C15.7906 13.9038 15.4544 14.24 15.4544 14.6538C15.4544 15.0676 15.7906 15.4038 16.2044 15.4038C16.6182 15.4038 16.9544 15.0676 16.9544 14.6538C16.9544 14.24 16.6182 13.9038 16.2044 13.9038Z"
											/>
										</g>
										<defs>
											<clipPath id="clip0_92_1779">
												<rect width="24" height="24" fill="white" />
											</clipPath>
										</defs>
									</svg>
								</span>
							</template>

							<template #append>
								<v-btn
									type="button"
									color="primary"
									class="rounded-small text-capitalize my-3"
									elevation="0"
									:disabled="canNotApplyVoucherCode"
									:loading="isApplyingVoucherCode"
									@click="handleVerifyVoucherCode"
									>Apply</v-btn
								>
							</template>
						</v-text-field>

						<span
							v-if="voucherMessage"
							class="mt-2 d-inline-block"
							:class="{ 'success--text': isVoucherCodeValid, 'red--text': !isVoucherCodeValid }"
						>
							{{ voucherMessage }}
						</span>
					</div>

					<div class="mt-16 pt-16">
						<v-btn
							:href="`${baseUrl}/place-order?token=${authToken}`"
							color="primary"
							class="pa-3 h-auto d-block w-full d-block rounded-small text-capitalize text-h6"
							:disabled="canSubmit"
						>
							<strong class="font-weight-bold">Checkout</strong>
						</v-btn>
					</div>
				</div>

				<div v-else class="d-flex align-center justify-center w-full h-full py-16">
					<p class="font-weight-bold text-lg text-center">Oops!! something went wrong</p>
				</div>
			</transition>
		</div>
	</v-card>
</template>

<script>
/* eslint-disable comma-dangle */
import debounceFn from 'debounce-fn'
import AuthMixin from '@/mixins/auth'
import Loader from '@/components/Loader.vue'
import currencyFormatter from '@/utils/currency-formatter'
import { getCalculatedDeliveryCharges, checkIsValidVoucherCode } from '@/services/order'
import { IMAGE_BASE_URL } from '@/constants'
import { SUCCESS, NOT_FOUND, EXISTS } from '@/constants/status-code'

export default {
	name: 'ShoppingCart',
	mixins: [AuthMixin],
	components: {
		Loader,
	},
	data() {
		return {
			IMAGE_BASE_URL,
			currencyFormatter,
			isLoading: false,
			isVoucherCodeValid: false,
			voucherCode: '',
			voucherMessage: '',
			isApplyingVoucherCode: false,
			orderAmountData: {
				deliveryCharge: 0,
				totalOrderAmount: 0,
				totalOrderAmountExcludeDeliveryCharge: 0,
				totalOrignalAmount: 0,
				totalProductWeight: 0,
				totalSaving: 0,
			},
		}
	},
	created() {
		if (this.hasCartProducts) {
			// if already has voucher code then remove it
			if (this.$store.state.voucherCodeId !== null) {
				this.$store.commit('RESET_VOUCHER_CODE_ID')
			}

			this.isLoading = true
			this.fetchCalculatedDeliveryChargesOfOrder()
			this.isLoading = false

			this.$watch(
				() => this.cartProductsQuantity,
				debounceFn(
					() => {
						this.fetchCalculatedDeliveryChargesOfOrder()
					},
					{ wait: 200 }
				)
			)
		}
	},
	watch: {
		voucherCode() {
			// voucher code is changed and it was valid before
			// so change it reset it
			if (this.isVoucherCodeValid) {
				this.$store.commit('RESET_VOUCHER_CODE_ID') // remove voucher code
				this.isVoucherCodeValid = false
			}

			// if no value then remove message
			if (this.voucherMessage) {
				this.voucherMessage = ''
			}
		},
		isVoucherCodeValid(_, oldValue) {
			// if voucher code was valid and now invalid
			if (oldValue) {
				this.$store.commit('RESET_VOUCHER_CODE_ID') // remove voucher code if has
			}

			// refech the order amount data and show apporpriate order amount data w/ delivery charges
			this.fetchCalculatedDeliveryChargesOfOrder()
		},
	},
	computed: {
		cartProducts() {
			return this.$store.state.cartProducts
		},
		cartProductsQuantity() {
			if (!this.hasCartProducts) return []
			return this.$store.state.cartProducts.map((product) => product.quantity)
		},
		hasCartProducts() {
			return Array.isArray(this.cartProducts) && this.cartProducts.length
		},
		discountCodeId() {
			return this.$store.state.voucherCodeId
		},
		canSubmit() {
			if (!this.voucherCode) return false
			// if added has voucher code only if voucher code is valid
			return !this.isVoucherCodeValid
		},
		canNotApplyVoucherCode() {
			// if no voucher code then no cant apply
			if (!this.voucherCode) return true

			// if has voucher code and it is valid then disable it
			if (this.voucherCode && this.isVoucherCodeValid) return true

			// else yes
			return false
		},
	},
	methods: {
		increaseProductQuantity(id) {
			const productId = parseInt(id, 10)
			this.$store.commit('INCREASE_PRODUCT_QUANTITY', productId)
		},
		decreaseProductQuantity(id) {
			const productId = parseInt(id, 10)
			this.$store.commit('DECREASE_PRODUCT_QUANTITY', productId)
		},
		async fetchCalculatedDeliveryChargesOfOrder() {
			if (!this.hasCartProducts) return

			const products = this.cartProducts.map((product) => ({
				productId: product.id,
				qtyOrdered: product.quantity,
			}))

			const payload = {
				orderItems: products,
				discountId: this.discountCodeId,
			}

			try {
				const res = await getCalculatedDeliveryCharges(this.authToken, { payload })
				const { data, statusCode } = res.data

				if (statusCode === SUCCESS) {
					this.orderAmountData.deliveryCharge = data.deliveryCharge
					this.orderAmountData.totalOrderAmount = data.totalOrderAmount
					this.orderAmountData.totalOrderAmountExcludeDeliveryCharge = data.totalOrderAmountExDC
					this.orderAmountData.totalOrignalAmount = data.totalOrignalAmount
					this.orderAmountData.totalProductWeight = data.totalProductWeight
					this.orderAmountData.totalSaving = data.totalSaving
				}
			} catch (e) {
				console.log(e, 'error')
			}
		},
		async handleVerifyVoucherCode() {
			this.$refs.voucherCodeRef.blur()
			if (!this.voucherCode) return

			this.isApplyingVoucherCode = true

			try {
				const res = await checkIsValidVoucherCode(this.authToken, { code: this.voucherCode })
				const { data, statusCode, message } = res.data

				if (statusCode === SUCCESS) {
					this.isVoucherCodeValid = true
					this.voucherMessage = 'Voucher code applied successfully'
					this.$store.commit('SET_VOUCHER_CODE_ID', data)
				}
				// not found or expired
				if (statusCode === NOT_FOUND && !data) {
					this.isVoucherCodeValid = false
					this.voucherMessage = message
					this.$store.commit('RESET_VOUCHER_CODE_ID') // remove
				}
				// already used it
				if (statusCode === EXISTS && !data) {
					this.isVoucherCodeValid = false
					this.voucherMessage = message
					this.$store.commit('RESET_VOUCHER_CODE_ID') // remove
				}
			} catch (e) {
				console.log(e, 'error')
				this.$store.commit('RESET_VOUCHER_CODE_ID') // remove
			}

			this.isApplyingVoucherCode = false
		},
	},
}
</script>

<style lang="scss" scoped>
:deep(.list-unstyle) {
	padding-left: 0;
}
:deep(.v-input__append-inner) {
	margin-top: 0px;
}
.theme--light.v-application .voucher-icon {
	color: rgba(0, 0, 0, 0.38);
}
.v-application .voucher-icon {
	color: rgba(255, 255, 255, 0.53);
}
</style>
